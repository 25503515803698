<template>
    <div class="page-content">
        <div class="container">
            <h1> {{ pageTitle }} </h1>
            <div class="section-small" v-if="courseData.length > 0">
                <CourseCard :courses="courseData" box="col-sm-3" />
            </div>
            <NoRecord v-if="courseData.length < 1"/>
        </div>
    </div>
</template>

<script>
import CourseCard from "../Course/CourseCard.vue";
import NoRecord from "@/components/NoRecord.vue";
import Translation from '../../../public/translation.json'

export default {
    components: {
        CourseCard, NoRecord
    },
    data(){
        return {
            breadcrumb: [
                {title: 'Home', path:'/'},
                {title: 'Contact Us', path:'#'}
            ],
            filePath: '',
            pageData: {},
            courseData: [],
            boxSize: 'col-sm-3',
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    computed: {
        pageTitle() {
            return Translation.pagetitle[this.defaultLang].favourite_courses
        }
    },
    methods: {
        getCourses() {
            this.$store.dispatch('favouriteCourses').then(res=>{    
                if(res.data.status === true) {                    
                    this.courseData = res.data.data;
                }
            })
            .catch(err=>{
                console.log(err)
            })
        }
    },
    created: function(){
        this.getCourses()
    }
}
</script>