<template>
    <div class="row">        
        <div class="mb-3" :class="boxSize" v-for="(item, index) of courseData" :key="index">
            <div class="course-card">
                <div class="course-card-thumbnail ctoverlay">
                    <img :src="item.image_name" :alt="item.title">
                    <span class="catagroy">{{ item.category }}</span> 
                </div>
                <div class="course-card-body">
                    <div class="course-card-info">
                        <div>                                                       
                            <div class="star-rating">
                                <span class="avg" v-if="item.avg_ratings > 0">{{ item.avg_ratings }}</span> 
                                <span class="star" v-for="index in item.avg_ratings" :key="index" ></span> 
                                <template v-if="item.avg_ratings < 5">      
                                    <span class="star empty" v-for="n in (5 - item.avg_ratings)" :key="n+'_r'"></span>     
                                </template>    
                            </div>
                        </div>   
                        <div class="wishlist-block" v-if="storeToken">
                            <a href="javascript:void(0)" class="favourite-btn btn btn-danger transition-3d-hover" >
                                <i class="favourite" 
                                    @click="addToFavourite(item.id, $event)"
                                    :class="(item.is_favourite) ? `icon-material-outline-favorite` : `icon-material-outline-favorite-border`"
                                ></i>
                            </a>
                        </div>                     
                    </div>
                    
                    <h4><router-link :to="`${$baseURL}user/course/${item.id}`">{{ item.title }}</router-link></h4>
                    <div class="course_content" v-if="item.short_content" v-html="item.short_content"></div>
                    <!-- <div class="course_content" v-if="item.short_content" v-html="item.short_content.substring(0, 100)"></div> -->
                    <div class="course-card-footer">
                        <h5> <i class="uil uil-clipboard-alt"></i>{{ item.total_topics }} {{ pageContent('topics') }}</h5>
                        <h5 class="last">
                            <div class="course-button">
                                <router-link :to="`${$baseURL}user/course/${item.id}`" v-if="storeToken">{{ pageContent('view_details') }} <i class="icon-feather-chevrons-right"></i></router-link>
                                <router-link :to="`${$baseURL}account/login`" v-else>{{ pageContent('enroll_now') }} <i class="icon-feather-chevrons-right"></i></router-link>
                            </div> 
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Translation from '../../../public/translation.json'

export default {
    props: {
        courses: Array,
        path: Object,
        box: String
    },
    data() {
        return {
            courseData: [],
            dirPath: '',
            boxSize: 'col-sm-4',
            ratings: 0,
            storeToken: this.$store.getters.storeToken || '',
            defaultLang: localStorage.getItem('_store_lang') || 'en'
        }
    },
    methods: {
        pageContent(field) {
            return Translation.content[this.defaultLang][field]
        },
        addToFavourite(courseId, el) {
            let targetEl = el.target,
                params = {
                    course_id: courseId
                }
            this.$http.post(`account/user/favourite-course`, params)
            .then(res=>{
                if(res.data.status === true) {
                    if(res.data.message === 'Removed') {
                        targetEl.classList.remove('icon-material-outline-favorite')
                        targetEl.classList.add('icon-material-outline-favorite-border')
                    }
                    else {
                        targetEl.classList.add('icon-material-outline-favorite')
                        targetEl.classList.remove('icon-material-outline-favorite-border')
                    }
                }
            })
            .catch(err => {
                console.log(err.response)
            })
        }
    },
    created: function(){
        this.courseData = this.$props.courses
        this.dirPath = this.$props.path
        this.boxSize = this.$props.box
        //console.log(this.$props.box)
    },
    watch: {
        "$props.courses"(){
            this.courseData = this.$props.courses
            this.boxSize = this.$props.box
            this.dirPath = this.$props.path
        },
    }
}
</script>

<style scoped>
.course_content{min-height: 70px;}
</style>